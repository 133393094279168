.form-style{
    background-color: white ;
    padding: 35px 40px;
    text-align: left;
    border-radius: 6px;
  }

.header-style{
    color: #152939;
    font-size: 1.15rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    padding-right: 180px;
}

.label-top{
    color: black;
    display: block;
    font-size: 0.875rem;
}

.label-time{
    color: black;
    display: block;
    font-size: 0.875rem;
}